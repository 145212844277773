<template>
  <figure>
    <a href="" @click.prevent="showModal = !showModal">
      <img :src="url" :alt="alt">
    </a>
    <figcaption>
      <slot></slot>
    </figcaption>
    <app-modal class="fluid" v-if="showModal" @close="showModal = false">
      <a href="" @click.prevent="showModal = !showModal">
        <img :src="url" :alt="alt">
      </a>
    </app-modal>
  </figure>
</template>
<script>
export default {
  props: ["url", "alt"],
  data () {
    return {
      showModal: false,
    }
  }
}
</script>